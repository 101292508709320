/**
 * RecaptchaEvents Class
 * @description lsd beljebb.
 * @author Sági György <gyorgy.sagi@w5labs.com>
 * @date 2019.04.09
 * @version 1.3
 */

/* IE 11 fixálás */
(function () {

    if ( typeof window.CustomEvent === "function" ) return false;

    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: null };
      var evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
  }

  window.CustomEvent = CustomEvent;
})();



(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('RecaptchaEvents', [], factory);
    } else {
        // Browser globals
        window.RecaptchaEvents = factory();
    }
}(this, function () {
    'use strict';

    /**
     * getLang
     * @description Nyelvválasztó
     * @param length - 2 vagy 5 karakter hosszú nyelvi kulcsot akarunk lekérni.
     * @returns {string}
     */
    const getLang = function (length) {
        let langDefaultMap = ["hu", "hu_HU"];

        length = length || 5;

        const langMap = {
            "2": 0,
            "5": 1
        };

        if (window.serverVariables && window.serverVariables.hasOwnProperty("siteLanguage") && window.serverVariables.siteLanguage[langMap[length]]) {
            return window.serverVariables.siteLanguage[langMap[length]];
        } else {
            if (langDefaultMap[langMap[length]]) {
                return langDefaultMap[langMap[length]];
            }
        }
        return langDefaultMap[0];
    };

    /**
     * Recaptcha API URL
     * @type {string}
     */
    const apiUrl = '//www.google.com/recaptcha/api.js?render=explicit&hl=' + getLang(2);

    /**
     * RecaptchaEvents Class
     * @description Fél-automata Recaptcha kezelő osztály. Implementálja az alapvető G.Recaptcha funkciókat eseményekkel.
     * A funckciók eseményekként az adott recapt. elemen triggerelhetőek. Az egyes funkciók a recapt. elemet átadva szintén
     * hívhatóak. Az execute funkció hívással egy Promise objektumot kapunk vissza amely feloldása esetén a user megoldotta
     * a captcha kihívást. :) Az execute esemény triggerelésekor a Promise objektum ugyanúgy feloldásra kerül, viszont
     * nem lehet rá feliratkozni. Ebben az esetben érdemes egy getResponse kérést intézni az elemmel.
     *
     * Nincs szükség a recaptcha API js-ének behúzására, a script ezt elvégzi ez az alap működéséhez szükséges(onload)
     *
     * Alap működéséhez létrehoz a globális scope-ban(window) egy RecaptchaEvents.map objektumot ahova a
     * recaptcha elemeket menti.
     * Objektum 1 adott kulcsa: a recaptcha elem id attribútuma
     * Objektum tartalma (magáért beszél):
     * {
     *      "id": elem.id,
     *      "sitekey": sitekey,
     *      "widgetID": widgetID,
     *      "promise": {
     *          "resolve": null,
     *          "reject": null
     *      }
     * }
     *
     * Invisible captcha esetén az elem data-callback attribútuma felülírásra kerül. Minden recaptcha elem
     * kap egy saját változót amely egy funkció a globális szkópban.
     * Ennek a funkciónak a prototipusa tartalmazza az elem id attribútumát prototype.elemId alatt.
     * Illetve prototype.elem alatt az adott recaptcha elem referenciáját is. Így tudjuk feloldani a
     * recaptcha elemen execute esemény közben létrehozott Promise-t.
     *
     * Recaptcha elemen triggerelt események:
     * - reseted - adott elemen recaptcha resetelése esetén.
     * - rendered - adott elemen recaptcha renderelésekor fut meg.
     * - executing - adott elemen a recaptcha érvényesítésekor fut meg. User által külsőleg triggerelt.Pl. form küldés, gomb nyomás.
     * - response - getResponse esemény triggerelése után az elemen triggerelődik egy response esemény
     * - resolved - adott elemen amikor megérkezett a válasz google-től, a g-recaptcha-response textarea értéke feltöltésre kerül.
     *
     * Recaptcha elemen triggerelhető események:
     * (Plusz feature hogy nem kell widgetID-t kezelni, elegendő ismerni a recaptcha elemet.)
     * - reset - megegyezik a google recaptcha v2 dokumentációjával.
     * - render - megegyezik a google recaptcha v2 dokumentációjával.
     * - execute - megegyezik a google recaptcha v2 dokumentációjával.
     * - getResponse - megegyezik a google recaptcha v2 dokumentációjával.
     *
     * Példák a demo könyvtárban.
     * - invisible.html - egy olyan ajaxos form kezelése ahol invisible captcha jelenik meg.
     *
     * @constructor
     */
    function RecaptchaEvents() {

        /**
         * beinjektáljuk a recaptcha api js-ét
         */
        this.appendApiScript();

        /**
         * Létrehozunk egy globálisan elérhető objektumot, ezen keresztül érhetőek el
         * a publikus funkciók.
         * @type {{reset: *, render: *, execute: *, getResponse: *}}
         */
        window.RecaptchaEvents.api = {
            "reset": this.__eventListeners.reset.bind(this),
            "render": this.__eventListeners.render.bind(this),
            "execute": this.__eventListeners.execute.bind(this),
            "getResponse": this.__eventListeners.getResponse.bind(this)
        };

        if (typeof window.RecaptchaEvents.map === "undefined") {
            window.RecaptchaEvents.map = {}
        }
    }

    RecaptchaEvents.prototype = {
        "__elements": [],
        /**
         * eseménykezelők objektuma
         */
        "__eventListeners": {
            /**
             * Renderelés eseménye.
             * @description Itt történik a globális window.RecaptchaEvents.map feltöltése is
             * @param elem
             */
            "render": function (elem) {
                let sitekey = this.checkElementHasSiteKey(elem);

                if (sitekey) {

                    elem.dataset.callback = this.overWriteCallback(elem);

                    try {
                        let widgetID = window.grecaptcha.render(elem.id, {'sitekey': sitekey});

                        window.RecaptchaEvents.map[elem.id] = {
                            "id": elem.id,
                            "sitekey": sitekey,
                            "widgetID": widgetID,
                            "promise": {
                                "resolve": null,
                                "reject": null
                            }
                        };

                        let renderedEvent = new Event("rendered");
                        elem.dispatchEvent(renderedEvent);

                    } catch (e) {
                        console.warn(e);
                    }

                }
            },
            /**
             * Reset eseménye
             * @param elem
             */
            "reset": function (elem) {
                if (window.RecaptchaEvents.map.hasOwnProperty(elem.id)) {
                    try {
                        window.grecaptcha.reset(window.RecaptchaEvents.map[elem.id].widgetID);
                        let resetedEvent = new Event("reseted");
                        elem.dispatchEvent(resetedEvent);
                    } catch (e) {
                        console.warn(elem.id + " captcha cannot be reseted.")
                    }

                }
            },
            /**
             * Recaptcha megoldása esemény
             * @param elem
             * @returns {Promise<any>}
             */
            "execute": function (elem) {
                const self = this;
                return new Promise(function (resolve, reject) {
                    if (window.RecaptchaEvents.map.hasOwnProperty(elem.id)) {
                        window.RecaptchaEvents.map[elem.id].promise.resolve = resolve;
                        window.RecaptchaEvents.map[elem.id].promise.reject = reject;
                        let executingEvent = new CustomEvent("executing", {"detail": {"promise": window.RecaptchaEvents.map[elem.id].promise}});
                        window.grecaptcha.execute(window.RecaptchaEvents.map[elem.id].widgetID);
                        elem.dispatchEvent(executingEvent);
                        if (self.checkIfElementIsInvisible(elem)) {
                            self.addSolveWindowExitonInvisibleCaptchaEventListener(elem);
                        }
                    } else {
                        reject({"error": "Element not found in recaptchaevent map object."});
                    }
                });
            },
            /**
             * Válasz visszakérése esemény
             * @param elem
             * @returns {string | undefined}
             */
            "getResponse": function (elem) {
                if (window.RecaptchaEvents.map.hasOwnProperty(elem.id)) {
                    elem.dataset.grecaptchaResponse = window.grecaptcha.getResponse(window.RecaptchaEvents.map[elem.id].widgetID);
                    let responseEvent = new CustomEvent("response", {"detail": {"response": elem.dataset.grecaptchaResponse}});
                    elem.dispatchEvent(responseEvent);
                    return elem.dataset.grecaptchaResponse;
                }
            },
        },
        /**
         * Inicializálás az összes megfelelő attribútumú elemen.
         */
        "init": function () {
            this.__elements = document.querySelectorAll("[data-recaptcha-toggle]");

            if (!this.__elements.length) {
                console.warn("Google Recaptcha elements not found.");
                return;
            }

            function waitForGlobal(key, sub, callback) {
                if (window[key]) {
                    if (sub) {
                        if (window[key][sub]) {
                            callback();
                        } else {
                            setTimeout(function () {
                                waitForGlobal(key, sub, callback);
                            }, 100);
                        }
                    } else {
                        callback();
                    }
                } else {
                    setTimeout(function () {
                        waitForGlobal(key, sub, callback);
                    }, 100);
                }
            }

            const initElements = function () {
                for (let key in this.__elements) {
                    if (this.checkElementHasID(this.__elements[key])) {
                        if (this.__elements[key] instanceof Node) {
                            this.addEventListeners(this.__elements[key]);
                            let renderEvent = new CustomEvent('render');
                            this.__elements[key].dispatchEvent(renderEvent);
                        }
                    } else {
                        console.warn("Found element with empty or uset ID attribute.");
                    }
                }
            };


            waitForGlobal("grecaptcha", "ready", (function () {
                window.grecaptcha.ready(initElements.bind(this));
            }).bind(this));

        },
        /**
         * Eseménykezelők hozzáadása
         * @param elem
         */
        "addEventListeners": function (elem) {
            elem.addEventListener("render", this.__eventListeners.render.bind(this, elem), true);
            elem.addEventListener("reset", this.__eventListeners.reset.bind(this, elem), true);
            /**
             * Ha invisible az elem akkor rakjuk csak fel az execute eseményt,
             * és írjuk felül a callback data attribútumot.
             */
            if (this.checkIfElementIsInvisible(elem)) {
                elem.addEventListener("execute", this.__eventListeners.execute.bind(this, elem));
            }
        },
        /**
         * Ha invisible captcha van akkor detektáljuk ha a user bezárta az ablakot,
         * ennek segítségével egy plusz működési módot tudunk létrehozni
         * @param elem
         */
        "addSolveWindowExitonInvisibleCaptchaEventListener": function (elem) {
            const recaptchaSelector = 'iframe[title*="recaptcha"]';
            const eventClass = 'recaptchaExitEventAdded';
            if (document.querySelector(recaptchaSelector) && document.querySelector(recaptchaSelector).parentElement && document.querySelector(recaptchaSelector).parentElement.previousElementSibling &&
                !document.querySelector(recaptchaSelector).parentElement.previousElementSibling.classList.contains(eventClass)
            ) {
                document.querySelector(recaptchaSelector).parentElement.previousElementSibling.classList.add(eventClass);
                document.querySelector(recaptchaSelector).parentElement.previousElementSibling.addEventListener('click', function () {
                    window.RecaptchaEvents.map[elem.id].promise.reject("Recaptcha window closed");
                    let closingEvent = new CustomEvent("closing");
                    elem.dispatchEvent(closingEvent);
                });
            }
        },
        /**
         * Viszgálat hogy invisible captchat inicializálunk-e
         * @param elem
         * @returns {string | undefined | boolean}
         */
        "checkIfElementIsInvisible": function (elem) {
            return (elem.dataset.size && elem.dataset.size === "invisible");
        },
        /**
         * Vizsgálat elem id-ra
         * @param elem
         * @returns {boolean}
         */
        "checkElementHasID": function (elem) {
            return elem.id !== null;
        },
        /**
         * Van az elemnek Sitekey kulccsa a dataset alatt?
         * @param elem
         * @returns {string | undefined}
         */
        "checkElementHasSiteKey": function (elem) {
            return elem.dataset.sitekey;
        },
        /**
         * Itt történik a mágia. Lásd a legfelső leírást.
         * @param elem
         * @returns {string}
         */
        "overWriteCallback": function (elem) {
            /**
             * String to Hash ezzel alakítunk ki egyedi hash az elem IDjából
             * @param str
             * @returns {number}
             */
            const elemIdToCustomHash = function (str) {
                var h = 0, l = str.length, i = 0;
                if (l > 0)
                    while (i < l)
                        h = (h << 5) - h + str.charCodeAt(i++) | 0;

                return Math.abs(h);
            };

            /**
             * Globális változó neve amelyet meghív a Recaptcha amikor a user megoldja a captchát
             * @type {string}
             */
            const elemIdCustomHash = "RecaptchaEventsCallbacks" + elemIdToCustomHash(elem.id);
            /**
             * createCallbackInGlobalScope
             * @description Létrehozzuk a globális(window) szkópban a feltebb kigenerált változó névvel a funkciót.
             * Prototype-ot bővítjuk elemmel és id-vel.
             * @param elem
             */
            const createCallbackInGlobalScope = function (elem) {
                window[elemIdCustomHash] = function (grecaptcharesponse) {
                    window.RecaptchaEvents.map[window[elemIdCustomHash].prototype.elemId].promise.resolve(grecaptcharesponse);
                    let challangeResolved = new CustomEvent("resolved", {"detail": {"response": grecaptcharesponse}});
                    window[elemIdCustomHash].prototype.elem.dispatchEvent(challangeResolved);
                };
                window[elemIdCustomHash].prototype.elemId = elem.id;
                window[elemIdCustomHash].prototype.elem = elem;
            };
            createCallbackInGlobalScope(elem);
            // strignként kell visszatérni
            return elemIdCustomHash;
        },
        /**
         * appendApiScript
         * @description létrehozza a recaptcha api js elemet és inicializálja.
         */
        'appendApiScript': function () {
            if (!document.querySelector("script#recaptchaApi")) {
                let script = document.createElement('script');
                script.id = "recaptchaApi";
                script.src = apiUrl;
                script.async = 'async';
                // script.defer = 'defer';
                script.onload = this.init.bind(this);

                document.querySelector('body').appendChild(script);
            } else {
                this.init();
            }
        },
    };

    window.RecaptchaEvents = RecaptchaEvents;

    return RecaptchaEvents;

}));
