/**
 * On document load
 */
window.addEventListener('load', function(e) {

});

$(function () {

    $('.fade').addClass('in');
    $('.fade-drop').addClass('in');

    $('.hero .card-wrapper').on('init',function(){
        $(".hero .slick-active").prev().addClass('prevdiv');
        $(".hero .slick-active").next().addClass('nextdiv');

        let prevImage = $('.hero .prevdiv').find('img').data('thumb');
        let nextImage = $('.hero .nextdiv').find('img').data('thumb');

        $('.prev-image').attr('src', prevImage);
        $('.next-image').attr('src', nextImage);
    });


    $('.hero .card-wrapper').slick({
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        pauseOnFocus: false,
        prevArrow: '<button class="prev"><div><i class="icon-prev"></i><img src="" alt="" class="prev-image"></div></button>',
        nextArrow: '<button class="next"><div><i class="icon-next"></i><img src="" alt="" class="next-image"></div></button>'
        // prevArrow: '<button class="prev"><div><i class="icon-prev"></i><div class="image prev-image"></div></div></button>',
        // nextArrow: '<button class="next"><div><i class="icon-next"></i><div class="image next-image"></div></div></button>'
    }).on('afterChange',function(){
        $(".hero .slick-active").removeClass('prevdiv nextdiv');
        $(".hero .slick-slide").removeClass('prevdiv nextdiv');
        $(".hero .slick-active").prev().addClass('prevdiv');
        $(".hero .slick-active").next().addClass('nextdiv');

        let prevImage = $('.hero .prevdiv').find('img').data('thumb');
        let nextImage = $('.hero .nextdiv').find('img').data('thumb');

        $('.prev-image').attr('src', prevImage);
        $('.next-image').attr('src', nextImage);
    });

    $('.news-slider-index').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        variableWidth: true,
        centerMode: true,
        rows: 0,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    infinite: true,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    infinite: true,
                    arrows: true,
                    prevArrow: '<button class="prev"><i class="icon icon-prev"></i></button>',
                    nextArrow: '<button class="next"><i class="icon icon-next"></i></button>'
                }
            }
        ]
    });

    $('.newsPage .news-slider-index').slick("unslick");

    $('.navbar-toggle').click(function () {
        $('.navbar').toggleClass('open');
        $('body').toggleClass('open');
        $('html').toggleClass('open');
    });

    // Menü váltás scrollra
    $(document).scroll(function () {
        let $nav = $(".navbar");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });

    $('.content .editable').find('img').parent('p').addClass('image');


    if($(window).width() > 1200) {
        function castParallax() {

            var opThresh = 350;
            var opFactor = 750;

            window.addEventListener("scroll", function(event){

                var top = this.pageYOffset;

                var layers = document.getElementsByClassName("parallax-item");
                var layer, speed, yPos;
                for (var i = 0; i < layers.length; i++) {
                    layer = layers[i];
                    speed = layer.getAttribute('data-speed');
                    var yPos = -(top * speed / 100);
                    layer.setAttribute('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px);transition: 0s all;transition-delay: 0s;');
                }
            });
        }

        document.body.onload = castParallax();
    }

    let active = $('.navbar .nav-item.active');
    $('.navbar .nav-item').hover(function() {
        $('.navbar .nav-item.active').removeClass('active'); // remove class from previous active item
        $(this).addClass('active'); // item class to the current one
    }, function() {
        $('.navbar .nav-item.active').removeClass('active'); // remove class from previous active item
        active.addClass('active'); // item class to the current one
    });
});
